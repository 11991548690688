@import "../../assets/scss/variables";

.sideMenuLayout {
  width: var(--side-nav-bar-width);
  z-index: 10;
  bottom: 0;
  position: fixed;
  top: 0;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .0s ease-in-out, right .0s ease-in-out;
  background-image: linear-gradient(var(--bs-white) 5%, var(--bs-white));

  &.isMobile {
    box-shadow: 8px 0 10px -6px rgba(154, 161, 171, .15);

    .sideMenu {
      width: var(--side-nav-bar-width);
      border-top-left-radius: 0;

      .sideMenuTitleSection {
        border-top-left-radius: 0;
      }
    }
  }
}

.sideMenu {
  width: calc(var(--side-nav-bar-width) - 30px);
  background-image: linear-gradient(var(--bs-white) 5%, var(--bs-white));
  background-repeat: no-repeat;
}

:global {
  .side-menu-item {
    margin-bottom: 4px;

    &.active {
      color: var(--bs-white) !important;
      background-color: var(--bs-primary) !important;
      background-image: none !important;
      border-radius: 10px !important;
    }

    &.accordion-button:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}

.sideMenuTitleSection {
  background-color: var(--bs-white) !important;
}

.sideMenuCollapsed {
  margin-left: var(--side-nav-bar-width-ne);
}

.sideMenuList {
  height: calc(100vh - var(--nav-header-height));
  overflow-y: auto;
}

.contentPage {
  margin-left: var(--side-nav-bar-width);
  overflow: hidden;
  padding-top: var(--nav-header-height);
  min-height: 100vh;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .50s ease-in-out, right .50s ease-in-out;
}

.contentPageFull {
  margin-left: 0;
}

.navHeader {
  height: var(--nav-header-height);
  position: fixed;
  left: var(--side-nav-bar-width);
  top: 0;
  right: 0;
  z-index: 1001;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
}

.navHeaderFull {
  left: 0;
}