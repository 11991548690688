@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "variables";
@import "bootstrap";

/*pages*/

:root {}

html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  font-family: 'Roboto', sans-serif !important;
}


.css-2xhzvc.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #202525 !important;
}
 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
border-color: #17201e !important;
}

::selection {
  color: white !important;
  background: $black !important;
}


.fs12 {
  font-size: 12px;
}
.css-ypiqx9-MuiDialogContent-root{
  width: 28rem !important;
  background-color: aliceblue;
  position: fixed !important;         
  top: 10px !important;          
  right: 10px !important;   
}
.fs13 {
  font-size: 13px;
}

.css-l3ic5x-MuiButtonBase-root-MuiCheckbox-root.Mui-checked
{
  color: #f2a408 !important;
}

.fs14 {
  font-size: 14px;
}

.textTransformNone {
  text-transform: none !important;
}

.loginButton {
  padding: 0.5rem 2rem;
  border: 1.4px solid white;
  border-radius: 4px;
}

.loginHover:hover {
  background-color: #183869;
}

.footerBg {
  background-color: #fcefc4;
}

.linkCreate {
  text-decoration: underline
}

.qualityTypes {
  border: 2px solid $secondary;
}

.borderDark {
  border: 2px solid black;
}
.borderLightDark {
  border: 1.4px solid black;
}

.activeCoverage {
  background-color: $primary;
  color: white;
}

// Mob view
@media only screen and (max-width: 600px) {
  .mobJustify {
    justify-content: center !important;
    flex-wrap: wrap !important;
  }
}